import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe) {}

  transform(value: number, isCurrency: boolean = false): string {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    // Determine the scale tier
    const tier = Math.max(0, Math.floor(Math.log10(Math.abs(value)) / 3));

    // 2 non-zero decimals by default (43.43344 => 43.43, 0.0000114201553151 => $0.000011)
    const digitsInfo = '1.0-' + (value.toString().match(/[1-9]/)?.index || 2);
    const scaledValue = value / Math.pow(10, tier * 3);

    return isCurrency
      ? this.currencyPipe.transform(scaledValue, '$', 'code', digitsInfo) + suffixes[tier]
      : this.decimalPipe.transform(scaledValue, digitsInfo) + suffixes[tier];
  }
}
