export const environment = {
  production: true,
  api_uri: 'https://api.my.staging.taxtank.com.au',
  apiV2: 'https://api.my.staging.taxtank.com.au/api',
  coreLogicUrl: 'https://api-uat.corelogic.asia',
  intercomAppId: 'cljp47bm',
  stripePk: 'pk_test_zgFrK7PFj2E2zRS2096AaQUC003ZnU7xOM',
  mercureUrl: 'https://mercure.staging.taxtank.com.au/.well-known/mercure',
  logoutTimeout: 2700, // 45 minutes
  enableMixpanel: true,
  // Sentry
  app_uri: 'https://my.staging.taxtank.com.au',
  SENTRY_DSN: 'https://ebcb502225744bff8452a03f41a9fb79@o343510.ingest.sentry.io/5839688',
  SENTRY_PROJECT: 'ttfront-stage',
  gtmId: '',
  // Social login
  facebookAppId: '3135372090062634',
  googleClientId: '1032292969842-ck57r927er88kbgpci582kbil4nevqgh.apps.googleusercontent.com',
  rewardfulId: '',
  googleAPIKey: 'AIzaSyDGdETttfBs5sDMZoaQT8fYO-pGLVi1oH4',
  playlistId: 'PLaibkGQwSaMp8kX9ndHgG7V4ybWHesHPN',
  accountantPlaylistId: 'PLaibkGQwSaMrKl2QsqlpPwIfXfKeShN4H',
  advisorPlaylistId: 'PLaibkGQwSaMqRNmA9WZ1U316jjzm8t4KM',
  // TaxTank support email (tt advisor account)
  ttAdvisor: 'help_staging@taxtank.com.au',
  // TaxTank accountant email
  ttAccountant: 'itp_staging@taxtank.com.au',
  mixpanelToken: '5affdbb53142b70b9faddfbf994cbaf8',
  sharesightUrl: 'https://edge-api.sharesight.com',
  sharesightClientId: '8062bba34024594b5370fb70832847f625430b48faae140d7fed4f8d502fe7af',
  sharesightRedirectUri: 'https://my.staging.taxtank.com.au/client/holdings-tank'
};
