import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MODULE_URL_LIST, User, UserService } from 'taxtank-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserRoleGuard  {
  constructor(private userService: UserService, private dialog: MatDialog, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userService.getFirst().pipe(map((user) => this.check(user, route)));
  }

  /**
   * If redirection was made through the application interface (via routerLink), leave it on the current page.
   * otherwise redirect the user to the dashboard.
   */
  private check(user: User, route: ActivatedRouteSnapshot): boolean {
    // if current subscription has no related item - forbid to go to desired page
    if (user.hasRoles(route.data.role)) {
      return true;
    }

    this.router.navigateByUrl(MODULE_URL_LIST.myAccount).then(() => {
      if (!route.data.dialog403) {
        return;
      }
      // Any redirect closes all dialogs. need to open dialog after
      this.dialog.open(route.data.dialog403, {
        panelClass: 'dialog-banner',
        disableClose: true,
      });
    });

    return false;
  }
}
